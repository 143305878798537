/* App.js */
import React, { Component } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class TotalRequestsPerProvince extends Component {
  constructor() {
    super();
    this.addSymbols = this.addSymbols.bind(this);
  }

  addSymbols(e) {
    var suffixes = ["", "K", "M", "B"];
    var order = Math.max(
      Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)),
      0
    );

    if (order > suffixes.length - 1) order = suffixes.length - 1;

    var suffix = suffixes[order];
    return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
  }

  render() {
    const options = {
      animationEnabled: true,
      theme: "light2", // "light1", "light2", "dark1", "dark2"
      title: {
        text: "Total Waste Collection Request: November 2024",
        fontSize: 25,
      },
      axisY: {
        title: "Number of Requests by Home Users",
        labelFormatter: this.addSymbols,
        scaleBreaks: {
          autoCalculate: true,
        },
      },
      axisX: {
        title: "Provinces",
        titleSize: 15,
        labelAngle: 90,
        labelFontSize: 12,
      },
      data: [
        {
          type: "column",
          dataPoints: [
            { label: "Gauteng", y: 68206498 },
            { label: "KwaZulu-Natal", y: 55897709 },
            { label: "Western Cape", y: 7570438 },
            { label: "Eastern Cape", y: 17453224 },
            { label: "Limpopo", y: 6389443 },
            { label: "Mpumalanga", y: 4815084 },
            { label: "Free State", y: 7631644 },
            { label: "North West", y: 5274321 },
            { label: "Northern Cape", y: 3297654 },
          ],
        },
      ],
    };

    return (
      <div>
        <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
        {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
      </div>
    );
  }
}

export default TotalRequestsPerProvince;
