import React from "react";
import { motion, useInView } from "framer-motion";
import { FaCalendarAlt } from "react-icons/fa";
import { useEffect, useState } from "react";

const LearningContent = ({ resources }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const ref = React.useRef(null);
  const isInView = useInView(ref, { triggerOnce: true });

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className="py-10 flex flex-col md:flex-row justify-between">
      <div className="grid grid-cols-2 gap-4 w-full md:w-2/3 p-4">
        {resources.youtube_links.map((video, index) => (
          <motion.div
            key={video.videoId}
            className={`w-full transform transition-opacity duration-1000 ease-in-out ${
              isLoaded ? "opacity-100" : "opacity-0"
            }`}
            style={{ transitionDelay: `${index * 200}ms` }}
            initial={{ opacity: 0, x: 100 }}
            animate={isLoaded ? { opacity: 1, x: 0 } : {}}
            transition={{
              delay: index * 0.2,
              duration: 0.8,
              ease: "easeOut",
            }}
          >
            <iframe
              className="w-full h-64 rounded-lg shadow-lg"
              src={video.ytLink}
              title={video.videoId}
              allowFullScreen
            />
          </motion.div>
        ))}
      </div>

      <motion.div
        ref={ref}
        className="w-full md:w-1/3 p-4"
        initial={{ opacity: 0, x: 100 }}
        animate={isInView ? { opacity: 1, x: 0 } : {}}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <h2 className="text-3xl font-semibold">{resources.title}</h2>
        <h3 className="mb-4 text-xs flex items-center">
          <FaCalendarAlt fill="gray" className="mr-2" /> Created:{" "}
          {resources.created_date}
        </h3>
        <p className="text-gray-600 mb-6">{resources.content}</p>
        <p className="text-gray-600 mb-4">{resources.content}</p>
      </motion.div>
    </div>
  );
};

export default LearningContent;
