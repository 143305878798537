import About from "./about/About";
import Benefits from "./home/Benefits";
import ContactForm from "./home/ContactForm";
import DownloadOurApps from "./home/DownloadTheApp";
import FAQ from "./home/FAQ";
import Footer from "./home/Footer";
import Gallery from "./home/Gallery";
import Hero from "./home/Hero";
import HowWeWork from "./home/HowWeWork";
import NavigationBar from "./home/NavigationBar";
import OurPartners from "./home/Partners";
import Services from "./home/Services";
import Stats from "./home/Stats";

const Home = () => {
  return (
    <div>
      <NavigationBar />
      <Hero />
      <Services />

      <HowWeWork heading={"How we work"} type={"book"} />
      <Benefits />
      <About isHomePage={true} />
      <DownloadOurApps />

      {/* <Gallery />
      <OurPartners /> */}
      {/* <Stats /> */}
      <FAQ />
      <ContactForm />

      <Footer />
    </div>
  );
};

export default Home;
