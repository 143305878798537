import React, { useState } from "react";
import { FaRegFilePdf } from "react-icons/fa6";

const PDFViewer = ({ pdfUrl }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        onClick={toggleModal}
        className="text-blue-600 underline text-m mb-2 flex"
      >
        <FaRegFilePdf className="mr-2" />
        User ID Document
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          onClick={toggleModal} // Close modal when clicking outside iframe
        >
          <div
            className="bg-white p-4 rounded shadow-lg relative"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside iframe
          >
            <button
              onClick={toggleModal}
              className="absolute top-2 right-2 text-gray-600"
            >
              ✕
            </button>
            <iframe
              src={pdfUrl}
              width="800px"
              height="500px"
              title="User ID Document"
              className="rounded-md"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
