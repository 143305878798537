import React from "react";
import { motion } from "framer-motion";
import arrow from "../../img/right-down.png"; // Import your arrow image here
import step1Illustration from "../../img/Waste management-amico.png";
import step2Illustration from "../../img/Mobile login-bro.png";
import step3Illustration from "../../img/Gift-bro.png";

// Step illustrations corresponding to each step
const illustrations = [step1Illustration, step2Illustration, step3Illustration];

const steps = [
  {
    title: "Households Sort Waste",
    description: (
      <>
        <strong>Instructions:</strong> Encourage households to separate
        recyclable materials into different bags (plastic, paper, glass).
        <br />
        <strong>Details:</strong> Remind users to rinse items to prevent
        contamination and keep recyclables ready for collection.
      </>
    ),
    color: "bg-purple-500",
  },
  {
    title: "Schedule Pickups via App",
    description: (
      <>
        <strong>Simple Scheduling:</strong> Guide on using the GreenPickr app to
        set convenient pickup times.
        <br />
        <strong>Notifications:</strong> Explain that households will receive
        reminders and updates about scheduled pickups to streamline the process.
      </>
    ),
    color: "bg-teal-500",
  },
  {
    title: "Earn & Track Rewards",
    description: (
      <>
        <strong>Green Points:</strong> Explain how households earn redeemable
        points with each pickup.
        <br />
        <strong>Track Progress:</strong> Outline how users can monitor their
        environmental impact and reward status within the app.
      </>
    ),
    color: "bg-orange-500",
  },
];

const stepVariants = {
  hidden: (i) => ({
    opacity: 0,
    y: i % 2 === 0 ? -30 : 30,
  }),
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.3, duration: 0.5 },
  }),
};

const HowWeWork = ({ heading, type }) => {
  return (
    <div className="bg-gray-100 py-12">
      {type === "book" && (
        <h2 className="text-m uppercase font-bold text-center mb-8">
          {heading}
          <span className="block mt-2 h-1 w-20 bg-primary-lighter mx-auto"></span>
        </h2>
      )}
      <div
        className={`max-w-7xl ${type === "book" ? "py-10" : ""} mx-auto px-4`}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 text-center">
          {" "}
          {/* Adjusted for 3 steps */}
          {steps.map((step, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center"
              custom={index}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: false, amount: 0.3 }}
              variants={stepVariants}
            >
              <img
                src={illustrations[index]} // Use the corresponding illustration for each step
                alt={`Illustration for ${step.title}`}
                className="mb-4 max-w-64 h-64 object-cover" // Adjust width and height as needed
              />
              <h3 className="mt-4 text-lg font-semibold">{step.title}</h3>
              <p className="mt-2 text-sm text-gray-600 text-left">
                {step.description}
              </p>

              {/* Add the arrow image between steps */}
              {/* {index < steps.length - 1 && (
                <img
                  src={arrow}
                  alt="Arrow pointing to next step"
                  className="mt-4 w-10 h-10" // Adjust width and height as needed
                />
              )} */}
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
