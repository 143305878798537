import React from "react";
import NavigationBar from "../home/NavigationBar";

import Footer from "../home/Footer";

const ForRecyclers = () => {
  return (
    <div>
      <NavigationBar />
      <div className="py-20">
        <h2 className="text-5xl  font-normal text-center  mb-8">
          Something about the recycling Statiton ♻️
        </h2>
      </div>

      <Footer />
    </div>
  );
};

export default ForRecyclers;
