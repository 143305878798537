import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { FaTruck } from "react-icons/fa";
import truckIconSrc from "../../../../img/garbage-truck (1).png";

// Custom truck icon
const truckIcon = new L.Icon({
  iconUrl: truckIconSrc,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

// Random locations in South Africa
const locations = [
  { city: "Johannesburg", lat: -26.2041, lng: 28.0473 },
  { city: "Cape Town", lat: -33.9249, lng: 18.4241 },
  { city: "Durban", lat: -29.8587, lng: 31.0218 },
  { city: "Pretoria", lat: -25.7461, lng: 28.1881 },
  { city: "Port Elizabeth", lat: -33.9608, lng: 25.6022 },
  { city: "Bloemfontein", lat: -29.0852, lng: 26.1596 },
  { city: "Nelspruit", lat: -25.4693, lng: 30.9766 },
  { city: "Kimberley", lat: -28.7282, lng: 24.7495 },
  { city: "Polokwane", lat: -23.9006, lng: 29.4508 },
  { city: "Pietermaritzburg", lat: -29.6168, lng: 30.3928 },
  { city: "George", lat: -33.9648, lng: 22.4592 },
  { city: "Rustenburg", lat: -25.6545, lng: 27.2559 },
  { city: "Vereeniging", lat: -26.6731, lng: 27.9261 },
  { city: "East London", lat: -33.0292, lng: 27.8546 },
  { city: "Mthatha", lat: -31.5889, lng: 28.7844 },
  { city: "Soweto", lat: -26.2485, lng: 27.8545 },
  { city: "Welkom", lat: -27.9864, lng: 26.7066 },
  { city: "Klerksdorp", lat: -26.8596, lng: 26.6208 },
  { city: "Secunda", lat: -26.5532, lng: 29.0962 },
  { city: "Tzaneen", lat: -23.8337, lng: 30.1646 },
  { city: "Stellenbosch", lat: -33.9346, lng: 18.8692 },
  { city: "Knysna", lat: -34.0363, lng: 23.0471 },
  { city: "Queenstown", lat: -31.8976, lng: 26.8825 },
  { city: "Upington", lat: -28.4487, lng: 21.2563 },
  { city: "Middelburg", lat: -25.7764, lng: 29.4637 },
  { city: "Lichtenburg", lat: -26.1527, lng: 26.1575 },
  { city: "Kathu", lat: -27.6959, lng: 23.0467 },
  { city: "Graaff-Reinet", lat: -32.2529, lng: 24.5307 },
  { city: "Worcester", lat: -33.6463, lng: 19.4485 },
];

const SanitationWorkersMap = () => {
  return (
    <div style={{ height: "60vh", width: "100%" }}>
      <MapContainer
        center={[-30.5595, 22.9375]} // Centered on South Africa
        zoom={6}
        scrollWheelZoom={true}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locations.map((location, index) => (
          <Marker
            key={index}
            position={[location.lat, location.lng]}
            icon={truckIcon}
          >
            <Popup>
              Sanitation Worker in <b>{location.city}</b>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default SanitationWorkersMap;
