import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaTachometerAlt,
  FaClipboardList,
  FaGift,
  FaUsers,
  FaBook,
  FaBell,
  FaUserCircle,
  FaCog,
  FaSignOutAlt,
} from "react-icons/fa";

import { MdRecordVoiceOver } from "react-icons/md";
import LogoutModal from "../components/LogoutModal";

const AdminSideBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;

  const [notificationCounter, setNotificationCounter] = useState(2);

  const currentPage = {
    fontWeight: "Bold",
    fontSize: "18px",
    backgroundColor: "#57cc99",
    borderRadius: "5px",
    padding: "6px",
  };

  const handleLogout = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmLogout = () => {
    navigate("/login");
  };

  return (
    <div className="h-full w-72 bg-primary-dark text-white flex flex-col justify-between">
      <div className="p-6">
        <div className="mb-8">
          <h1 className="text-2xl font-bold">GreenPickr Portal</h1>
        </div>

        <ul className="mt-20 space-y-4">
          <li
            style={pathname === "/admin/dashboard" ? currentPage : null}
            className="flex items-center"
          >
            <FaTachometerAlt className="text-lg mr-3" />
            <Link to="/admin/dashboard" className="text-m">
              Dashboard
            </Link>
          </li>
          <li
            style={
              pathname.indexOf("/admin/requests") !== -1 ? currentPage : null
            }
            className="flex items-center"
          >
            <FaClipboardList className="text-lg mr-3" />
            <Link to="/admin/requests" className="text-sm">
              Requests
            </Link>
          </li>
          <li
            style={
              pathname.indexOf("/admin/rewards") !== -1 ? currentPage : null
            }
            className="flex items-center"
          >
            <FaGift className="text-lg mr-3" />
            <Link to="/admin/rewards" className="text-sm">
              Manage Rewards
            </Link>
          </li>
          <li
            style={pathname.indexOf("/admin/users") !== -1 ? currentPage : null}
            className="flex items-center"
          >
            <FaUsers className="text-lg mr-3" />
            <Link to="/admin/users" className="text-sm">
              Manage Users
            </Link>
          </li>
          <li
            style={
              pathname.indexOf("/admin/reports") !== -1 ? currentPage : null
            }
            className="flex items-center"
          >
            <FaUsers className="text-lg mr-3" />
            <Link to="/admin/reports" className="text-sm">
              Analytics and Reports
            </Link>
          </li>
          <li
            style={
              pathname.indexOf("/admin/learning") !== -1 ? currentPage : null
            }
            className="flex items-center"
          >
            <FaBook className="text-lg mr-3" />
            <Link to="/admin/learning" className="text-sm">
              Training and Learning
            </Link>
          </li>
          <li
            style={
              pathname.indexOf("/admin/notifications") !== -1
                ? currentPage
                : null
            }
            className="flex items-center"
          >
            <FaBell className="text-lg mr-3" />
            <Link to="/admin/notifications" className="text-sm">
              Notifications{" "}
              <span className="bg-red-500 text-xs px-1 rounded-sm">
                {notificationCounter}
              </span>
            </Link>
          </li>

          <li
            style={
              pathname.indexOf("/admin/support") !== -1 ? currentPage : null
            }
            className="flex items-center"
          >
            <MdRecordVoiceOver className="text-2xl mr-2" />
            <Link to="/admin/support" className="text-sm">
              Customer Support
            </Link>
          </li>
        </ul>
      </div>
      <div className="p-6">
        <ul className="space-y-4">
          <li
            style={
              pathname.indexOf("/admin/profile") !== -1 ? currentPage : null
            }
            className="flex items-center"
          >
            <FaUserCircle className="text-lg mr-3" />
            <Link to="/admin/profile" className="text-sm">
              Profile
            </Link>
          </li>
          <li
            style={
              pathname.indexOf("/admin/settings") !== -1 ? currentPage : null
            }
            className="flex items-center"
          >
            <FaCog className="text-lg mr-3" />
            <Link to="/admin/settings" className="text-sm">
              Settings
            </Link>
          </li>
          <li className="flex items-center">
            <FaSignOutAlt className="text-lg mr-3" />
            <button onClick={handleLogout} className="text-sm">
              Log Out
            </button>
          </li>
        </ul>
      </div>
      <LogoutModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={confirmLogout}
      />
    </div>
  );
};

export default AdminSideBar;
