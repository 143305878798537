import React from "react";

const ApproveRequestModal = ({
  isOpen,
  onClose,
  onConfirm,
  requestNumber,
  requesterUser,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <p className="mb-4 text-black">
            Are you sure you want to Approve this request{" "}
            <strong>{requestNumber}</strong> from <br />
            <strong>{requesterUser}</strong>?
          </p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-primary-light text-white rounded hover:bg-primary-lightd"
            >
              Yes Approve
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApproveRequestModal;
