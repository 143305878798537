import React from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { motion } from "framer-motion";

const ContactForm = () => {
  // Define the motion variants for animation
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="py-10"
      initial="hidden"
      whileInView="visible"
      variants={variants}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-m uppercase font-bold text-center text-[#22223b] mb-8">
        Contact US
        <span className="block mt-2 h-1 w-10 bg-primary-light mx-auto"></span>
      </h2>
      <div className="px-4 md:px-10 rounded mb-4 flex flex-col lg:flex-row">
        {/* Contact Information */}
        <div className="lg:w-1/2 flex items-center mb-6 lg:mb-0 lg:mr-10">
          <div className="flex-col">
            <div className="mb-4">
              <p className="text-center text-xl">
                If you wish to reach out to us, <br /> please fill in your
                details. Our team will get back to you soon.
              </p>
            </div>
            <div className="flex flex-col items-center p-4 bg-white rounded-lg">
              <div className="flex items-center mb-2">
                <FaPhone className="text-primary mr-2" />
                <span className="text-gray-700">+27 767430043</span>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="text-primary mr-2" />
                <span className="text-gray-700">support@greenpickr.com</span>
              </div>
            </div>
          </div>
        </div>

        {/* Form Section */}
        <div className="lg:w-1/2 flex flex-col p-6">
          <div className="mb-6">
            <div className="mb-6">
              <input
                className="appearance-none border rounded-lg w-full md:w-1/2 py-3 px-3 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Your Name"
              />
            </div>
            <div className="mb-6">
              <input
                className="appearance-none border rounded-lg py-3 w-full md:w-1/2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                id="mobile-number"
                type="text"
                placeholder="Your Mobile Number"
              />
            </div>
            <div className="mb-6">
              <input
                className="appearance-none border rounded-lg py-3 w-full md:w-1/2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                id="email-id"
                type="email"
                placeholder="Your Email"
              />
            </div>
            <button className="transition ease-in-out delay-150 flex items-center px-4 py-2 bg-primary-light text-white rounded-md hover:-translate-y-1 hover:scale-110 hover:duration-300">
              <IoIosSend className="mr-2" fill="#ffffff" />
              Submit
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ContactForm;
