import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import logo from "../../img/1.png";

const NavigationBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    const styleEl = document.createElement('style');
    styleEl.innerHTML = `
      @keyframes spin-y {
        0% { transform: rotateY(0deg); }
        100% { transform: rotateY(360deg); }
      }
      
      .perspective-500 {
        perspective: 500px;
      }
      
      .logo-container:hover .logo-image {
        transform: rotateY(180deg);
      }
      
      .logo-image {
        transition: transform 0.7s;
      } 
    `;

    document.head.appendChild(styleEl);

    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);

  const currentPage = {
    fontWeight: "bold",
    fontSize: "18px",
    borderBottom: "3px solid white", 
  };

  const navItems = [
    { label: 'Home', path: '/' },
    { label: 'Services', path: '/recyclers' },
    { label: 'Company', path: '/learn-at-gp' },
    { label: 'Blog', path: '/sanitation-workers' },
    { label: 'About', path: '/about' },
    { label: 'Contact Us', path: '/contact' }
  ];

  return (
    <nav className="bg-primary-light shadow-md sticky top-0 z-50">
      <div className="container mx-auto px-6 md:px-0 py-3">
        {/* Mobile layout */}
        <div className="flex justify-between items-center md:hidden">
          <Link to="/" className="flex items-center perspective-500">
            <div className="bg-white p-1 rounded-full overflow-hidden shadow-md logo-container">
              <img
                src={logo}
                alt="GreenPickr Logo"
                className="h-11 w-auto object-contain logo-image"
              />
            </div>
          </Link>
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="text-white focus:outline-none md:hidden"
            aria-label="Toggle menu"
          >
            {isMenuOpen ? "✖" : "☰"}
          </button>
        </div>

        {/* Desktop layout */}
        <div className="hidden md:flex justify-between items-center">
          {/* Logo */}
          <div className="w-1/4">
            <Link to="/" className="flex items-center perspective-500">
              <div className="bg-white p-2 rounded-full overflow-hidden shadow-md logo-container">
                <img
                  src={logo}
                  alt="GreenPickr Logo"
                  className="h-12 w-auto object-contain logo-image"
                />
              </div>
            </Link>
          </div>

          {/* Centered navigation with space */}
          <div className="absolute left-0 right-0 flex justify-center items-center">
            <div className="flex items-center space-x-6 lg:space-x-8">
              {navItems.map(item => (
                <Link key={item.path} to={item.path}>
                  <button
                    style={pathname === item.path ? currentPage : {}}
                    className={`font-medium text-white text-xs lg:text-sm uppercase
    border-b-2 border-transparent hover:border-[#38a169]
    transition duration-200 ease-in-out`}
                  >
                    {item.label}
                  </button>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div
          className={`${isMenuOpen ? "flex" : "hidden"}
            md:hidden flex-col bg-primary-light w-full
            space-y-4 pt-4`}
        >
          {navItems.map(item => (
            <Link key={item.path} to={item.path}>
              <button
                style={pathname === item.path ? currentPage : {}}
                className={`font-medium text-white text-sm uppercase w-full text-left
                  border-b-2 border-transparent hover:border-[#57cc99]
                  transition duration-200 ease-in-out px-2 py-1`}
              >
                {item.label}
              </button>
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;