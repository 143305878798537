import AdminLayOut from "../../../components/AdminLayOut";

const Rewards = () => {
  return (
    <AdminLayOut>
      <div className="flex-1 p-6">
        {/* Add your Rewards content here */}
        <h1 className="text-2xl font-bold">Rewards</h1>
        <p className="mt-4">This is the Rewards page content.</p>
      </div>
    </AdminLayOut>
  );
};

export default Rewards;