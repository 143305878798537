import AdminLayOut from "../../../components/AdminLayOut";
import { IoAddCircleSharp } from "react-icons/io5";
import LearningCardComponent from "./LearningCardComponent";
import LearningModal from "./LearningModal";
import { useState } from "react";

const Learning = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (formData) => {
    console.log(formData);
    // Handle form data submission logic here
  };
  
  return (
    <AdminLayOut>
      <div className="flex-1 p-6">
        {/* Add your Learning content here */}
        <h1 className="text-2xl font-bold">Learning</h1>
        <div className="flex justify-between">
          <p className="mt-4">Click the Add button to addd new content</p>
          <button
            onClick={handleOpenModal}
            className="flex items-center px-4 py-2 text-sm font-semibold text-white bg-primary-light rounded hover:bg-primary-lightd"
          >
            <IoAddCircleSharp className="mr-2" size={25} />
            Add Content
          </button>
        </div>

        <div className="py-3">
          <LearningCardComponent />
        </div>
      </div>
      <LearningModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
      />
    </AdminLayOut>
  );
};

export default Learning;