import { useEffect, useState } from "react";
import axios from "axios";
import AdminLayOut from "../../../components/AdminLayOut";
import { baseUrl } from "../../../global";

const Dashboard = () => {
  const [totalRequest, setTotalRequests] = useState(0);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch total requests
    axios
      .get(
        `${baseUrl}/sanitationWorkerRequests/totalRequest`
      )
      .then((res) => {
        if (res.data) {
          setTotalRequests(res.data.newTotalRequests);
        }
      })
      .catch((error) => {
        console.error("Error fetching total requests:", error);
      });
    
    // Fetch sanitation worker requests
    fetchSanitationWorkerRequests();
  }, []);

  const fetchSanitationWorkerRequests = async () => {
    try {
      const response = await fetch(`${baseUrl}/sanitationWorkerRequests`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      // Map all fields exactly as in the original data structure
      const formattedUsers = data.map(request => ({
        user_id: request.user_id,
        first_name: request.user.first_name,
        last_name: request.user.last_name,
        gender: request.user.gender,
        contact_no: request.user.contact_no,
        email: request.user.email,
        registration_date: request.user.registration_date,
        registration_status: request.user.registration_status,
        registration_no: request.user.registration_no,
        request_no: request.request_no,
        status: request.status,
        locations: request.locations.map(loc => loc.address),
        workSchedules: request.workSchedules.map(schedule => ({
          day_of_week: schedule.day_of_week,
          start_time: schedule.start_time,
          end_time: schedule.end_time
        }))
      }));
      
      setUsers(formattedUsers);
      console.log(formattedUsers);
    } catch (error) {
      console.error('Error fetching sanitation worker requests:', error);
      setUsers([]);
    }
  };

  return (
    <AdminLayOut>
      <div className="flex w-full h-screen bg-gray-100">
        <div className="flex-1 flex flex-col">
          <div className="p-6 flex grid-cols-1 md:grid-cols-2 gap-6">
            <div className="w-52 bg-primary-light text-white p-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
              <h2 className="text-xl font-bold">New Requests</h2>
              <p className={`mt-4 ${totalRequest > 0 ? "text-3xl" : "text-m"}`}>
                {totalRequest > 0 ? totalRequest : "No new requests"}
              </p>
            </div>
            <div className="w-52 bg-[#f07167] text-white p-6 rounded-lg shadow-md transform transition-transform hover:scale-105">
              <h2 className="text-xl font-bold">New Users</h2>
              <p className="mt-4 text-3xl">{users.length}</p>
            </div>
          </div>
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-4">User List</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {users.map((user, userIndex) => (
                <div key={`user-${user.user_id}-${userIndex}`} className="bg-white p-4 rounded-lg shadow-md">
                  <h3 className="text-lg font-semibold">{user.first_name} {user.last_name}</h3>
                  <p className="text-sm text-gray-600">Status: {user.status}</p>
                  <p className="text-sm text-gray-500">Email: {user.email}</p>
                  <p className="text-sm text-gray-500">Contact: {user.contact_no}</p>
                  <p className="text-sm text-gray-500">Gender: {user.gender}</p>
                  <p className="text-xs text-gray-400 mt-2">Reg #: {user.registration_no}</p>
                  <p className="text-xs text-gray-400">Reg Status: {user.registration_status}</p>
                  <p className="text-xs text-gray-400">Request #: {user.request_no}</p>
                  {user.locations.length > 0 && (
                    <div className="mt-2">
                      <p className="text-xs font-semibold">Locations:</p>
                      <ul className="text-xs text-gray-500">
                        {user.locations.map((location, locIndex) => (
                          <li key={`loc-${user.user_id}-${locIndex}`}>{location}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {user.workSchedules.length > 0 && (
                    <div className="mt-2">
                      <p className="text-xs font-semibold">Work Schedule:</p>
                      <ul className="text-xs text-gray-500">
                        {user.workSchedules.map((schedule, scheduleIndex) => (
                          <li key={`schedule-${user.user_id}-${scheduleIndex}`}>
                            {schedule.day_of_week}: {schedule.start_time} - {schedule.end_time}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </AdminLayOut>
  );
};

export default Dashboard;