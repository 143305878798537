import React, { useState, useEffect, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import faqImg from "../../img/FAQs-amico.png";
import { motion } from "framer-motion";

const faqs = [
  {
    question: "What is waste management, and why is it important?",
    answer:
      "Waste management is the process of collecting, processing, recycling, or disposing of waste materials. Effective waste management helps reduce pollution, conserve natural resources, and protect the environment by preventing hazardous waste from contaminating the ecosystem.",
  },
  {
    question: "How can households participate in waste management?",
    answer:
      "Households can participate by sorting waste into recyclables and non-recyclables, composting organic waste, and responsibly disposing of hazardous materials. Scheduling regular pickups with recycling services also ensures proper waste handling.",
  },
  {
    question: "What items are recyclable?",
    answer:
      "Common recyclable items include paper, cardboard, glass bottles, aluminum cans, and certain plastics. Always check with local recycling guidelines as not all plastics are accepted, and items should be cleaned before recycling.",
  },
  {
    question: "What are the benefits of recycling electronic waste?",
    answer:
      "Recycling e-waste prevents toxic materials from polluting the environment, reduces the demand for raw materials, and recovers valuable components such as metals, which can be reused in manufacturing.",
  },
  {
    question:
      "How does GreenPickr support waste pickers and recycling efforts?",
    answer:
      "GreenPickr empowers waste pickers by providing fair compensation, connecting them with households and businesses for direct pickups, and offering training and resources to help them enhance their skills and increase recycling rates.",
  },
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [inView, setInView] = useState(false);
  const faqRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.3 }
    );

    if (faqRef.current) {
      observer.observe(faqRef.current);
    }

    return () => {
      if (faqRef.current) {
        observer.unobserve(faqRef.current);
      }
    };
  }, []);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <motion.div
      ref={faqRef}
      initial={{ opacity: 0, y: 30 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className="mx-auto px-6 sm:px-10 lg:px-20 py-8"
    >
      <div className="flex flex-col lg:flex-row justify-evenly items-center">
        <div className="lg:w-1/2 text-center lg:text-left">
          <h2 className="text-2xl font-bold mb-6">
            Frequently Asked Questions
          </h2>
          <p className="mb-4">
            Have a look into what most people are asking about GreenPickr
          </p>
          <img
            src={faqImg}
            alt="Illustration for FAQs"
            className="mb-6 w-full max-w-xs lg:max-w-md mx-auto lg:mx-0"
          />
        </div>
        <div className="w-full lg:w-1/2 space-y-4">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              className="border-b p-4"
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ delay: index * 0.1, duration: 0.5 }}
            >
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <h3 className="text-xl font-medium">{faq.question}</h3>
                {openIndex === index ? (
                  <FaChevronUp className="text-primary-light" />
                ) : (
                  <FaChevronDown className="text-primary-light" />
                )}
              </div>
              {openIndex === index && (
                <p className="mt-2 text-gray-700">{faq.answer}</p>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default FAQ;
