/* TopPerformingCitiesPerProvince.js */
import React, { Component } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
// var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class TopPerformingCitiesPerProvince extends Component {
  render() {
    const options = {
      animationEnabled: true,
      title: {
        text: "Top Requests in Gauteng",
        fontSize: 25,
      },
      subtitles: [
        {
          text: "",
          verticalAlign: "center",
          fontSize: 15,
          fontWeight: "light",
          dockInsidePlotArea: true,
        },
      ],
      data: [
        {
          type: "doughnut",
          showInLegend: true,
          indexLabel: "{name}: {y} Requests",
          yValueFormatString: "#,###",
          dataPoints: [
            { name: "Johannesburg", y: 120 },
            { name: "Pretoria", y: 95 },
            { name: "Soweto", y: 80 },
            { name: "Centurion", y: 60 },
            { name: "Midrand", y: 50 },
            { name: "Boksburg", y: 45 },
            { name: "Sandton", y: 40 },
            { name: "Benoni", y: 35 },
          ],
        },
      ],
    };
    return (
      <div>
        <CanvasJSChart
          options={options}
          /* onRef={ref => this.chart = ref} */
        />
        {/* You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods */}
      </div>
    );
  }
}

export default TopPerformingCitiesPerProvince;
