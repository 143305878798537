import React from 'react';
import households from '../../img/households.jpg';
import wastePickers from '../../img/waste-pickers.jpg';
import businesses from '../../img/businesses.jpg';

const sections = [
  {
    title: "For Households",
    image: households,
    imageAlt: "Colored recycling containers",
    imagePosition: "left",
    benefits: [
      {
        title: "Environmental Impact",
        description: "Each household actively contributes to reducing waste, lowering pollution, and fostering a cleaner environment for the community."
      },
      {
        title: "Rewards System",
        description: "Households earn Green Points for recycling, which can be redeemed for goods at partner retailers, promoting sustainable habits."
      },
      {
        title: "Convenience",
        description: "The GreenPickr platform allows easy at-home recycling with scheduled pickups, reducing the effort needed to contribute to sustainability."
      }
    ]
  },
  {
    title: "For Waste Pickers (Green Champions)",
    image: wastePickers,
    imageAlt: "Waste pickers at work",
    imagePosition: "right",
    benefits: [
      {
        title: "Fair Compensation",
        description: "Green Champions receive fair wages for their essential work, recognizing their contribution to environmental health."
      },
      {
        title: "Training Opportunities",
        description: "Green Champions gain access to valuable training in recycling, safety, and waste management, enhancing their skills and efficiency."
      },
      {
        title: "Direct Market Access",
        description: "Provides waste pickers with direct connections to buyers, increasing their income potential by opening new markets."
      }
    ]
  },
  {
    title: "For Businesses",
    image: businesses,
    imageAlt: "Business recycling",
    imagePosition: "left",
    benefits: [
      {
        title: "Sustainable Waste Management",
        description: "GreenPickr enables businesses to streamline waste management, showing commitment to sustainable operations and environmental responsibility."
      },
      {
        title: "Social Responsibility",
        description: "By supporting Green Champions and sustainable waste practices, businesses reinforce their role in community health and sustainability."
      },
      {
        title: "Reputation Building",
        description: "Promotes the brand's image as a leader in sustainability, helping to build trust and loyalty with environmentally-conscious consumers."
      }
    ]
  }
];

const BenefitsSection = () => {
  return (
    <div className="p-4 md:p-8 lg:p-10">
      <h1 className="text-4xl font-bold text-center mb-16">
        Benefits of GreenPickr
      </h1>
      
      {sections.map((section, index) => (
        <div key={index} className="mb-16">
          <div className="flex flex-col md:flex-row items-center gap-8">
            {section.imagePosition === "left" && (
              <div className="w-full md:w-1/2">
                <img
                  src={section.image}
                  alt={section.imageAlt}
                  className="w-full h-auto rounded-lg"
                />
              </div>
            )}

            <div className="w-full md:w-1/2">
              <h2 className="text-2xl font-bold mb-6">{section.title}</h2>
              
              <div className="space-y-6">
                {section.benefits.map((benefit, benefitIndex) => (
                  <div key={benefitIndex}>
                    <h3 className="text-lg font-semibold mb-2">{benefit.title}:</h3>
                    <p className="text-gray-700">
                      {benefit.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {section.imagePosition === "right" && (
              <div className="w-full md:w-1/2">
                <img
                  src={section.image}
                  alt={section.imageAlt}
                  className="w-full h-auto rounded-lg"
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BenefitsSection;