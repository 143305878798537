import React from "react";
import NavigationBar from "../home/NavigationBar";

import Footer from "../home/Footer";
import AboutFeatures from "./AboutFeatures";
import { motion, useInView } from "framer-motion";
import hereoImage from "../../img/hero1g.jpg";

const About = ({ isHomePage = false }) => {
  return (
    <div>
      {!isHomePage && <NavigationBar />}
      <div className="">
        <div className="bg-white text-gray-800">
          {/* Hero Section */}
          <section
            className="relative bg-cover bg-center h-[400px] flex items-center justify-center"
            style={{ backgroundImage: `url(${hereoImage})` }}
          >
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <motion.h1
              className="relative text-center text-4xl md:text-5xl text-white font-bold"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              About GreenPikr
            </motion.h1>
          </section>

          {/* Combined Introduction and Mission Section */}
          <section className="py-12 px-6 md:px-12 bg-gray-50">
            <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-8 items-center">
              {/* Introduction Section */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.3 }}
              >
                <h2 className="text-3xl font-semibold mb-4 text-primary-light">
                  Our Vission
                </h2>
                <p className="text-l text-gray-600 ">
                  We envision a world where waste is viewed as a valuable
                  resource, where communities and businesses actively
                  participate in sustainable practices, and where informal waste
                  pickers have access to fair opportunities, training, and
                  markets that acknowledge their essential role in environmental
                  sustainability.
                </p>
              </motion.div>

              {/* Mission Section */}
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.3 }}
                className="flex  md:flex-row items-center gap-8"
              >
                <div>
                  <h2 className="text-3xl font-semibold mb-4 text-primary-light">
                    Our Mission
                  </h2>
                  <p className="text-l text-gray-600">
                    GreenPickr is on a mission to create a sustainable future by
                    connecting households, businesses, and informal waste
                    pickers—our Green Champions—to increase recycling, reduce
                    landfill waste, and support economic empowerment for
                    underrepresented communities.
                  </p>
                </div>
              </motion.div>
            </div>
          </section>
          <motion.section
            className="py-12  px-6 md:px-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.3 }}
          >
            <div className="max-w-3xl mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.3 }}
                className="flex  md:flex-row items-center gap-8"
              >
                <div>
                  <h2 className="text-3xl font-semibold mb-4 text-primary-light">
                    Our Story
                  </h2>
                  <p className="text-l text-gray-600">
                    GreenPickr was born from a need to address the dual issues
                    of environmental waste and social inequality. Recognizing
                    the lack of organized recycling systems in underserved areas
                    and the vital yet undervalued role of informal waste
                    pickers, we created a digital platform that unites
                    households, businesses, and waste pickers in the recycling
                    process. By bridging these groups, GreenPickr makes waste
                    management accessible, rewarding, and impactful.
                  </p>
                </div>
              </motion.div>
            </div>
          </motion.section>
          {/* Services Section */}
          {/* Your existing Services Section code here */}

          {/* Testimonials Section */}
          <motion.section
            className="py-12 bg-gray-50 px-6 md:px-12"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.3 }}
          >
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl text-gray-400 font-semibold mb-6">
                Client Testimonials
              </h2>
              <p className="text-lg italic text-primary-light">
                "GreenPickr has completely transformed the way my family and I
                approach recycling. Scheduling pickups is so easy, and knowing
                that our waste is directly helping local waste pickers, or
                'Green Champions,' adds a personal touch. We even earn rewards
                through their Green Points system, which my kids love! It feels
                amazing to know we're making a real impact on the environment
                and supporting our community at the same time. Thank you,
                GreenPickr, for making it easy to live sustainably!"
              </p>
              <p className="mt-4 text-gray-500">- Satisfied Patient</p>
            </div>
          </motion.section>

          {/* Contact Section */}
        </div>
      </div>
      {!isHomePage && (
        <>
          <></>
          <AboutFeatures />
          <Footer />
        </>
      )}
    </div>
  );
};

export default About;
