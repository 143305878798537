import React, { useState } from "react";
import AdminNavigation from "../../../components/AdminNavigation";
import AdminSideBar from "../../../components/AdminSideBar";
import { useLocation, useParams } from "react-router-dom";
import RequestersRadius from "./RequestersRadius";
import Breadcrumb from "../../../components/Breadcrumb";
import DeclineRequestModal from "../../../components/DeclineRequestModal";
import MessageRequestModal from "../../../components/MessageRequesterModal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApproveRequestModal from "../../../components/ApproveRequestModal";
import { useNavigate } from "react-router-dom";
import { FaTrash, FaUser } from "react-icons/fa";
import PDFViewer from "./PDFViewer";
import { baseUrl } from "../../../global";

const ManageRequesterProfile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenMsg, setIsModalOpenMsg] = useState(false);
  const [isModalOpenApprove, setIsModalOpenApprove] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [message, setMessage] = useState("");

  const location = useLocation();
  const request = location.state?.request;
  const requesterId = request.request_id;
  const userId = request.user_id;

  const navigate = useNavigate();

  const createCitiesObj = (locations) => {
    if (locations.length === 0) {
      return "No locations yet";
    }

    const cities = {};
    locations.forEach((location) => {
      const { latitude, longitude, address } = location;
      const cityAbbreviation = address.split(",")[1].trim();
      cities[cityAbbreviation] = {
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      };
    });

    return cities;
  };

  const handleDeclineRequest = (e) => {
    e.preventDefault();
    if (!declineReason) {
      alert("please enter decline reason");
      return;
    }
    axios
      .put(
        `${baseUrl}/sanitationWorkerRequests/declineRequest`,
        { request_id: requesterId, message: declineReason }
      )
      .then((res) => {
        const success = res.data.success;
        if (success) {
          //show toast that the request was decline
          toast.success("Decline sent!");
          setIsModalOpen(false);
          setDeclineReason("");
        }
      });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!message) {
      alert("please enter the message");
      return;
    }
    axios
      .put(
        `${baseUrl}/sanitationWorkerRequests/sendMessage`,
        {
          user_id: userId,
          message: message,
        }
      )
      .then((res) => {
        const success = res.data.success;
        if (success) {
          //show toast that the request was decline
          toast.success(`Message sent to ${request.user.first_name}`);
          setIsModalOpenMsg(false);
          setMessage("");
        }
      });
  };

  console.log("URL ID", request.id_copy_url);

  const handleApproveRequest = () => {
    axios
      .post(
        `${baseUrl}/sanitationWorkerRequests/AcceptRequest`,
        {
          user_id: userId,
          request_id: requesterId,
          vehicle_name: request.vehicle_name,
          vehicle_model: request.vehicle_model,
          vehicle_registration_no: request.vehicle_registration_no,
          id_no: request.identity_number,
          license_code: request.license_code,
          preferred_radius: 10,
          date_of_birth: request.date_of_birth,
        }
      )
      .then((res) => {
        const success = res.data.success;
        const message = res.data.message;
        if (success) {
          toast.success("Request approved!");
          setIsModalOpenApprove(false);
          //delay a bit to show the success toast
          setTimeout(() => {
            navigate("/admin/requests");
            window.location.reload();
          }, 4000);
        } else {
          setIsModalOpenApprove(false);
          toast.error(`Error: ${message}`);
        }
      })
      .catch((error) => {
        toast.error(`An error occurred: ${error.message}`);
      });
  };

  const cities = createCitiesObj(request.locations);
  const [selectedCity, setSelectedCity] = useState(cities.JHB);

  const closeModal = () => {
    setIsModalOpen(false);
    setDeclineReason("");
  };
  const closeModalMsg = () => {
    setIsModalOpenMsg(false);
    setMessage("");
  };

  const closeModalApprove = () => {
    setIsModalOpenApprove(false);
  };
  const openDeclineModal = () => {
    setIsModalOpen(true);
  };
  const handleCityChange = (event) => {
    setSelectedCity(cities[event.target.value]);
  };
  return (
    <div className="flex w-full h-screen bg-gray-100">
      <div className="sticky top-0 h-screen overflow-hidden">
        <AdminSideBar />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="sticky top-0 z-10">
          <AdminNavigation />
        </div>
        <ToastContainer position="top-center" />
        <DeclineRequestModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onConfirm={handleDeclineRequest}
          declineReason={declineReason}
          setDeclineReason={setDeclineReason}
          requestNumber={request.request_no}
          userName={request.user.first_name}
          modalType="decline"
        />

        <MessageRequestModal
          isOpen={isModalOpenMsg}
          onClose={closeModalMsg}
          onConfirm={handleSendMessage}
          message={message}
          setMessage={setMessage}
          requestNumber={request.request_no}
          username={request.user.first_name}
        />

        <ApproveRequestModal
          isOpen={isModalOpenApprove}
          onClose={closeModalApprove}
          onConfirm={handleApproveRequest}
          requestNumber={request.request_no}
          requesterUser={`${request.user.first_name} ${request.user.last_name}`}
        />
        <div className="flex-1 overflow-y-auto p-6">
          <div className="pl-5">
            <Breadcrumb
              firstPage={"Requests"}
              secondPage={request.request_no}
            />
          </div>
          {/* Add your Learning content here */}
          <div className="p-4 w-full mx-auto">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="flex flex-col md:flex-row p-6">
                {/* Profile Picture and Basic Info */}
                <div className="flex flex-col items-center md:items-start md:w-1/3">
                  {request.image ? (
                    <img
                      className="h-36 w-36 rounded-full"
                      src={request.image}
                      alt=""
                    />
                  ) : (
                    <div className="h-36 w-36 rounded-full bg-gray-200 flex items-center justify-center">
                      <FaUser className="h-20 w-20 text-gray-500" />
                    </div>
                  )}

                  <h2 className="text-xl font-semibold mb-1">User Details</h2>
                  <p className="text-gray-500 mb-4">{`${request.user.first_name} ${request.user.last_name}`}</p>
                  <div className="col py-1">
                    <div className="flex justify-between">
                      <span>
                        <strong>Request Number:</strong> {request.request_no}
                      </span>
                    </div>
                    <div className="flex justify-between">
                      <span>
                        <strong>Assigned Worker ID:</strong>{" "}
                        {request.worker.worker_no}
                      </span>
                    </div>
                  </div>
                  <div className="flex space-x-2 mb-4">
                    {request.worker.worker_no === "NOT ASSIGNED" && (
                      <button
                        onClick={(e) => setIsModalOpenApprove(true)}
                        className="px-4 py-2 text-sm text-white bg-primary-light rounded hover:bg-primary-lightd"
                      >
                        Approve Request
                      </button>
                    )}
                    <button
                      onClick={(e) => setIsModalOpenMsg(true)}
                      className="flex items-center px-4 py-2 text-sm text-white bg-primary-dark rounded hover:bg-primary-dark"
                    >
                      Send Message
                    </button>
                    {request.worker.worker_no === "NOT ASSIGNED" && (
                      <button
                        onClick={openDeclineModal}
                        className="flex items-center px-4 py-2 text-sm text-white bg-red-500 rounded hover:bg-red-600"
                      >
                        Decline
                      </button>
                    )}
                  </div>

                  <div>
                    <h2 className="text-xl font-semibold mb-1">Documents</h2>
                    <PDFViewer pdfUrl={request.id_copy_url} />
                  </div>
                </div>

                {/* Detailed Info */}
                <div className="md:w-2/3 mt-6 md:mt-0 md:ml-6">
                  <div className="grid p-4 rounded-lg bg-gray-100 grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <h3 className="text-gray-700 font-semibold">
                        SA ID Number
                      </h3>
                      <p className="text-gray-500">{request.identity_number}</p>
                    </div>

                    <div>
                      <h3 className="text-gray-700 font-semibold">Gender</h3>
                      <p className="text-gray-500">{request.user.gender}</p>
                    </div>
                    <div>
                      <h3 className="text-gray-700 font-semibold">Full Name</h3>
                      <p className="text-gray-500">{`${request.user.first_name} ${request.user.last_name}`}</p>
                    </div>
                    <div>
                      <h3 className="text-gray-700 font-semibold">Mobile</h3>
                      <p className="text-gray-500">{request.user.contact_no}</p>
                    </div>
                    <div>
                      <h3 className="text-gray-700 font-semibold">Email</h3>
                      <p className="text-gray-500">{request.user.email}</p>
                    </div>
                    <div>
                      <h3 className="text-gray-700 font-semibold">Address</h3>
                      <p className="text-gray-500">
                        {request.locations.length <= 0
                          ? "Waiting profile Completion"
                          : request.locations[0].address}
                      </p>
                    </div>
                  </div>

                  {/* Editable Text Sections */}
                  <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="p-4 bg-gray-100 rounded-lg shadow">
                      <h3 className="text-gray-700 font-semibold">
                        Registration Details:
                      </h3>
                      <div className="mt-2 space-y-2">
                        <div className="pt-3 flex justify-between">
                          <span>
                            <strong>Work ID:</strong> {request.worker.worker_no}
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span>
                            <strong>Date of Registration:</strong>{" "}
                            {request.user.registration_date
                              .replace("T", " ")
                              .replace("Z", " ")
                              .replace(".000", "")}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="p-4 bg-gray-100 rounded-lg shadow">
                      <h3 className="text-gray-700 font-semibold">
                        Work Information:
                      </h3>
                      <div className="mt-2 space-y-2">
                        <div className="pt-3 flex justify-between">
                          <span>
                            <strong>Areas of Operation:</strong>{" "}
                            {request.locations
                              .map((location) =>
                                location.address
                                  .split(",")[1]
                                  .trim()
                                  .toUpperCase()
                              )
                              .join(", ")}
                          </span>
                        </div>

                        <div className="flex justify-between">
                          <span>
                            <strong>Types of Waste Collected:</strong> Plastic
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span>
                            <strong>Work Schedule:</strong> 3 to 5 hours a day
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span>
                            <strong>
                              Average Volume of Waste Collected per Day/Week:
                            </strong>{" "}
                            50 g p/d
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-5">
                <div className="flex justify-between items-center py-2">
                  <h3 className="pb-2 text-gray-700 font-semibold">
                    Radius of Sanitation Worker
                  </h3>
                  <select
                    onChange={handleCityChange}
                    className="block px-4 py-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    {Object.keys(cities).map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>

                <RequestersRadius center={selectedCity} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};



export default ManageRequesterProfile;
