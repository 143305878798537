import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { IoSearchSharp, IoNotifications } from "react-icons/io5";
import LogoutModal from "../components/LogoutModal";
import { FaUserCircle, FaSignOutAlt } from "react-icons/fa";

const AdminNavigation = () => {
  const { currentUser } = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationCounter, setNotificationCounter] = useState(2);
  const navigate = useNavigate();

  // Function to get user initials
  const getInitials = (name, surname) => {
    if (!name || !surname) return "";
    return `${name.charAt(0).toUpperCase()}${surname.charAt(0).toUpperCase()}`;
  };

  const handleLogout = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const confirmLogout = () => {
    // Add logout logic here, e.g., clear auth context, local storage, etc.
    navigate("/admin-login");
  };

  return (
    <div className="h-20 flex items-center text-sm text-gray-600 bg-white shadow-md">
      <div className="w-full flex items-center justify-between p-5">
        <div className="flex items-center">
          <Link to="/" className="no-underline">
            {/* <img src={logo} alt="logo" width="100" className="mt-1" /> */}
          </Link>
        </div>
        <div className="flex items-center">
          {/* Search Icon */}
          <div className="relative flex items-center mr-5 cursor-pointer">
            {!showSearchInput && (
              <IoSearchSharp
                size={25}
                onClick={() => setShowSearchInput(!showSearchInput)}
              />
            )}
            {showSearchInput && (
              <input
                type="text"
                className="ml-2 p-1 border rounded w-96"
                placeholder="Search..."
                autoFocus
                onBlur={() => setShowSearchInput(false)}
              />
            )}
          </div>
          {/* Notification Icon */}
          <Link to="/admin/notifications">
            <div className="relative flex items-center mr-5 cursor-pointer">
              <IoNotifications size={25} />
              {notificationCounter > 0 && (
                <span className="absolute ml-3 mb-5   bg-red-500 text-white rounded-full text-xs px-1">
                  {notificationCounter}
                </span>
              )}
            </div>
          </Link>
          {/* User Initials and Dropdown Menu */}
          <div className="relative flex items-center cursor-pointer">
            <div
              className="flex items-center justify-center font-bold w-10 h-10 bg-gray-200 rounded-full"
              onClick={() => setShowMenu(!showMenu)}
            >
              {getInitials(currentUser.name, currentUser.surname)}
            </div>
            {showMenu && (
              <div className="absolute right-0 mt-44 w-auto bg-white border border-gray-200 rounded-lg shadow-lg">
                <div className="px-4 py-3 border-b border-gray-200">
                  <p className="font-semibold">{`${currentUser.name} ${currentUser.surname}`}</p>
                  <p>{currentUser.username}</p>
                </div>
                <div className="px-4 py-2">
                  <Link
                    to="/admin/profile"
                    className="block text-gray-700 hover:bg-gray-100 rounded-md px-2 py-1 flex items-center"
                  >
                    <FaUserCircle className="mr-2" />
                    Profile
                  </Link>
                </div>
                <div className="px-4 py-2 border-t border-gray-200">
                  <button
                    className="w-full text-left text-gray-700 hover:bg-gray-100 rounded-md px-2 py-1 flex items-center"
                    onClick={handleLogout}
                  >
                    <FaSignOutAlt className="mr-2" />
                    Logout
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <LogoutModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onConfirm={confirmLogout}
        />
      </div>
    </div>
  );
};

export default AdminNavigation;
