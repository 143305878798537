import { useState } from "react";
import AdminLayOut from "../../../components/AdminLayOut";
import Request from "./Request";
import RequestsTable from "./RequestsTable";
import Breadcrumb from "../../../components/Breadcrumb";

const Requests = () => {
  const [statusFilter, setStatusFilter] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  
  return (
    <AdminLayOut>
      <div className="flex-1 p-8">
        <div className="pl-5">
          <Breadcrumb firstPage={"Requests"} />
        </div>
        <h1 className="text-2xl font-bold">Requests</h1>

        <div className="flex items-center justify-between py-3">
          <div>
            <span className="text-gray-700">Filter By Status: </span>
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              className=" text-gray-700 py-2 px-4 rounded"
            >
              <option value="All">All</option>
              <option value="Approved">Approved</option>
              <option value="Pending Approval">Pending Approval</option>
              <option value="Declined">Declined</option>
            </select>
          </div>
          <div className="flex space-x-4">
            <input
              type="email"
              id="email"
              value={searchQuery}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              placeholder="Search Request number..."
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <RequestsTable
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>
    </AdminLayOut>
  );
};

export default Requests;