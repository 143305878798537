import { useContext } from "react";
import AdminNavigation from "../../../components/AdminNavigation";
import AdminSideBar from "../../../components/AdminSideBar";
import { AuthContext } from "../../../context/AuthContext";

const Profile = () => {
  const { currentUser } = useContext(AuthContext);

  return (
    <div className="flex w-full h-screen bg-gray-100">
      <AdminSideBar />
      <div className="flex-1 flex flex-col">
        <AdminNavigation />
        <div className="flex-1 p-6">
          {/* Add your Profile content here */}
          <h1 className="text-2xl font-bold">Manage your Profile</h1>
          <div className=" pt-8 flex flex-col items-center md:items-start md:w-1/3">
            <div>
              <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-blue-500 mb-4">
                <img src="https://bit.ly/3vaOTe1" alt="User" />
              </div>
              <h2 className="text-xl font-semibold mb-1">User Name</h2>
              <p className="text-gray-500 mb-4">{currentUser.username}</p>
            </div>
            <div className="col py-1">
              <div className="flex justify-between">
                <span>
                  <strong>Admin Name:</strong>{" "}
                  {`${currentUser.name} ${currentUser.surname}`}
                </span>
              </div>
              <div className="flex justify-between">
                <span>
                  <strong>Contact:</strong> {currentUser.contact}
                </span>
              </div>
            </div>
            <div className="flex py-5 space-x-2 mb-4">
              <button className="px-4 py-2 text-sm text-white bg-primary-light rounded hover:bg-primary-lightd">
                Update Information
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
