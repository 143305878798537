import React from "react";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import phoneImage from "../../img/phone2.jpg";
import { TiTick } from "react-icons/ti"; // Adjust this import based on your actual image location
import { motion } from "framer-motion";

const DownloadOurApps = () => {
  const titleAnimation = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const contentAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <div className="py-10  px-4">
      <motion.h2
        className="text-m uppercase font-bold text-center pb-5 text-[#22223b] mb-8"
        variants={titleAnimation}
        initial="hidden"
        whileInView="visible"
      >
        Download the GreenPickr App
        <span className="block mt-2 h-1 w-10 bg-primary-light mx-auto"></span>
      </motion.h2>
      <div className="flex flex-col lg:flex-row items-center justify-center">
        <div className="lg:w-1/2 flex justify-center">
          <img
            src={phoneImage}
            alt="Phone"
            width={400}
            className="rounded-lg shadow-lg"
          />
        </div>
        <motion.div
          className="lg:w-1/2 flex flex-col items-center lg:items-start text-center lg:text-left mt-10 lg:mt-0"
          variants={contentAnimation}
          initial="hidden"
          whileInView="visible"
        >
          <h2 className="text-l font-bold mb-6">
            As a citizen, you can make a significant impact by recycling your
            waste. Download our GreenPickr App today to start your journey
            towards a cleaner and greener environment!
            <br />
            With our app, you'll enjoy features such as:
          </h2>
          <ul className="text-left mb-6 space-y-2">
            <li className="flex items-center">
              <TiTick className="text-primary-light mr-2" /> Easy waste sorting
            </li>
            <li className="flex items-center">
              <TiTick className="text-primary-light mr-2" /> Schedule convenient
              pickups
            </li>
            <li className="flex items-center">
              <TiTick className="text-primary-light mr-2" /> Earn rewards for
              recycling
            </li>
            <li className="flex items-center">
              <TiTick className="text-primary-light mr-2" /> Track your
              environmental impact
            </li>
          </ul>

          <div className="flex flex-col space-y-4">
            <a
              href="https://play.google.com/store"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center bg-primary-light text-white px-6 py-3 rounded-lg hover:bg-primary-lightd transition duration-300"
            >
              <FaGooglePlay className="mr-3" />
              Download on PlayStore
            </a>
            <a
              href="https://www.apple.com/app-store/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center bg-primary-light text-white px-6 py-3 rounded-lg hover:bg-primary-lightd transition duration-300"
            >
              <FaApple className="mr-3" />
              Download on iOS
            </a>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default DownloadOurApps;
