import React from "react";
import { motion } from "framer-motion";

const features = [
  {
    title: "Connecting with Informal Waste Pickers",
    description:
      "Individuals can connect directly with informal waste pickers to arrange the pickup of recyclable waste from their homes.",
  },
  {
    title: "Scheduling Pickups",
    description:
      "Users can schedule pickups, making it convenient to manage recycling.",
  },
  {
    title: "Tracking Recycled Materials",
    description: "Users can track the types of materials recycled.",
  },
  {
    title: "Viewing Environmental Impact",
    description:
      "Users can view the impact of their recycling efforts in terms of environmental benefits.",
  },
];

const AboutFeatures = () => {
  return (
    <div className="px-4 py-5">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8">
          GreenPickr Features
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className={`${
                index === 0 || index === 3 ? "bg-white" : "bg-green-50"
              } p-6 flex flex-col justify-between`}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2, duration: 0.6 }}
              viewport={{ once: true, amount: 0.3 }}
            >
              <h3 className="text-xl font-semibold mb-4">{feature.title}</h3>
              <p>{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutFeatures;
