import React, { ReactNode } from "react";
// import SideBar from "../components/SideBar";
// import TopNavBar from "../components/TopNavBar";
import AdminNavigation from "../components/AdminNavigation";
import AdminSideBar from "../components/AdminSideBar";


const AdminLayOut = ({ children }) => {
    return (
        <div className="flex h-screen overflow-hidden">
            {/* Sidebar */}
            <AdminSideBar />

            {/* Main Content */}
            <div className="flex-1 flex flex-col">
                {/* Top Navbar */}
                <AdminNavigation />

                {/* Scrollable Content */}
                <main className="flex-grow overflow-auto p-2 bg-gray-100 h-[calc(100vh-64px)] scrollbar-hide">
                    {children}
                </main>
            </div>
        </div>
    );
};

export default AdminLayOut;