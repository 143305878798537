import React from "react";

const DeclineRequestModal = ({
  isOpen,
  onClose,
  onConfirm,
  requestNumber,
  modalType,
  declineReason,
  setDeclineReason,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-4 text-black">Decline Request</h2>
          <p className="mb-4 text-black">
            Are you sure you want to <strong>Decline</strong> request:{" "}
            <strong>{requestNumber}</strong> ?
          </p>

          <form onSubmit={onConfirm}>
            <textarea
              value={declineReason}
              className="w-full h-24 p-2 border rounded"
              placeholder="Enter decline reason..."
              onChange={(e) => setDeclineReason(e.target.value)}
            ></textarea>
            <div className="flex justify-end space-x-4">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
              >
                No
              </button>
              <button
                type="submit"
                onClick={onConfirm}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Yes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeclineRequestModal;
