import React, { Component } from "react";
import CanvasJSReact from "@canvasjs/react-charts";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class TopRequestedWasteCategory extends Component {
  constructor() {
    super();
    this.addSymbols = this.addSymbols.bind(this);
  }

  addSymbols(e) {
    const suffixes = ["", "K", "M", "B"];
    const order = Math.max(
      Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)),
      0
    );

    if (order > suffixes.length - 1) return CanvasJS.formatNumber(e.value);

    const suffix = suffixes[order];
    return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
  }

  render() {
    const options = {
      animationEnabled: true,
      theme: "light2",
      title: {
        text: "Top Requested Waste Categories",
        fontSize: 25,
      },
      axisX: {
        title: "Waste Categories",
        reversed: false,
      },
      axisY: {
        title: "Number of Requests",
        includeZero: true,
        labelFormatter: this.addSymbols,
      },
      data: [
        {
          type: "bar",
          dataPoints: [
            { y: 250000, label: "Plastic" },
            { y: 200000, label: "Metal" },
            { y: 180000, label: "Cardboard" },
            { y: 150000, label: "Organic" },
            { y: 140000, label: "Paper" },
            { y: 130000, label: "Glass" },
            { y: 120000, label: "Mix" },
            { y: 100000, label: "Other" },
          ],
        },
      ],
    };

    return (
      <div>
        <CanvasJSChart
          options={options}
          /* onRef={ref => this.chart = ref} */
        />
        {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
      </div>
    );
  }
}

export default TopRequestedWasteCategory;
