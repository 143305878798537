import { SiAnilist } from "react-icons/si";
import AdminNavigation from "../../../components/AdminNavigation";
import AdminSideBar from "../../../components/AdminSideBar";
import ActiveHomeUsersPerMonth from "./charts/ActiveHomeUsersPerMonth";
import TopPerformingCitiesPerProvince from "./charts/TopPerformingCitiesPerProvince";
import TopRequestedWasteCategory from "./charts/TopRequestedWasteCategory";
import TotalRequestsPerProvince from "./charts/TotalRequestsPerProvince";
import SanitationWorkersMap from "./charts/SanitationWorkersMap";

const Reports = () => {
  return (
    <div className="flex w-full h-screen bg-gray-100">
      <AdminSideBar />
      <div className="flex-1 flex flex-col">
        <AdminNavigation />
        <div className="flex-1 p-6 overflow-y-auto">
          {" "}
          {/* Added overflow-y-auto */}
          <h1 className="text-2xl font-bold mb-6">Reports</h1>
          <div>
            <div className="flex flex-wrap gap-6 pb-6">
              <div className="flex-1 bg-white shadow rounded-lg p-4">
                <TotalRequestsPerProvince />
              </div>
              <div className="flex-1 bg-white shadow rounded-lg p-4">
                <ActiveHomeUsersPerMonth />
              </div>
              <div className="flex-1 bg-white shadow rounded-lg p-4">
                <TopPerformingCitiesPerProvince />
              </div>
            </div>

            <div className="flex flex-wrap gap-6 pb-6">
              <div className="flex-1 bg-white shadow rounded-lg p-4">
                <TopRequestedWasteCategory />
              </div>
            </div>
            <div className="flex flex-wrap gap-6">
              <div className="flex-1 bg-white shadow rounded-lg p-4">
                <h3 className="text-center text-xl ">
                  Sanitation workers Distribution Across the Country
                </h3>
                <SanitationWorkersMap />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
