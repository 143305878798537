import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-primary-dark2 text-white py-8">
      <div className="container mx-auto px-4 flex flex-wrap justify-between">
        <div className="w-full md:w-1/3 mb-6 md:mb-0">
          <h3 className="text-xl font-semibold mb-4">Products</h3>
          <ul className="list-none space-y-2">
            <li>Marketplace</li>
            <li>Sustainability</li>
            <li>Sanitation Workers</li>
            <li>Digital Thought</li>
          </ul>
        </div>
        <div className="w-full md:w-1/3 mb-6 md:mb-0">
          <h3 className="text-xl font-semibold mb-4">Company</h3>
          <ul className="list-none space-y-2">
            <li>About us</li>
            <li>Our Values at GreenPickr</li>
            <li>Resources</li>
            <li>Contact Us</li>
            <li>support@greenpickr.com</li>
            <li>+27 60 733 0236</li>
          </ul>
        </div>
        <div className="w-full md:w-1/3">
          <h3 className="text-xl font-semibold mb-4">Physical Address</h3>
          <address className="not-italic mb-4">
            Green Pickr
            <br /> Corner Black Crake and Kleinsperwer Street
            <br /> Block XX, Soshanguve  0152
          </address>
          <div className="flex">
            <FaFacebook className="mr-2" />
            <FaTwitter className="mr-2" />
            <FaLinkedin className="mr-2" />
            <FaInstagram className="mr-2" />
            <FaYoutube />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
