import React from "react";
import NavigationBar from "../home/NavigationBar";
import ContactForm from "../home/ContactForm";
import Footer from "../home/Footer";

const Contact = () => {
  return (
    <div>
      <NavigationBar />
      <div className="py-20">
        <h2 className="text-5xl  font-normal text-center  mb-8">
          We'd Love to Hear From You
        </h2>
      </div>
      <ContactForm />
      <Footer />
    </div>
  );
};

export default Contact;
