import React from "react";
import { motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa";
import icons from "./ServicesIcons";

const services = [
  {
    title: "For Households",
    description:
      "We make recycling simple by enabling households to sort their waste, schedule pickups, and earn rewards through our Green Points system, making it easy to contribute to a cleaner community.",
    icon: icons[0],
  },
  {
    title: "For Green Champions",
    description:
      "We empower informal waste pickers by providing fair compensation, access to training, and direct market connections, helping them enhance their livelihoods and play an integral role in waste management.",
    icon: icons[1],
  },
  {
    title: "For Businesses",
    description:
      "GreenPickr offers a seamless way for businesses to manage recyclable waste responsibly, connect directly with Green Champions, and demonstrate commitment to environmental sustainability.",
    icon: icons[2],
  },
  {
    title: "Waste Audits",
    description:
      "We offer waste audit services for businesses to help them understand their waste patterns and improve recycling rates.",
    icon: icons[3],
  },
  {
    title: "Electronic Waste Recycling",
    description:
      "GreenPickr provides e-waste recycling services, ensuring safe disposal and recovery of valuable materials from electronic waste.",
    icon: icons[4],
  },
  {
    title: "Secure Document Destruction",
    description:
      "We ensure secure shredding and recycling of sensitive documents, helping businesses protect their data while recycling responsibly.",
    icon: icons[5],
  },
];

const serviceVariants = {
  hidden: (i) => ({
    opacity: 0,
    y: i % 2 === 0 ? -30 : 30,
  }),
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: { delay: i * 0.2, duration: 0.5 },
  }),
};

const WasteServices = () => {
  return (
    <div className="p-4 md:p-8 lg:p-10 text-center">
      <h2 className="text-lg uppercase font-bold text-center mb-8">
        Our services
        <span className="block mt-2 h-1 w-20 bg-primary-lighter mx-auto"></span>
      </h2>
      <p className="text-md text-gray-600 mb-8">
        We offer a variety of services to meet the needs of households, waste
        pickers, and businesses. Explore how GreenPickr can help make recycling
        easier and more effective.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className={`${
              index % 2 === 1 ? "bg-white" : "bg-green-50"
            } p-6 flex flex-col justify-between w-full`}
            custom={index}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={serviceVariants}
          >
            <div className="text-4xl mb-4 text-left">{service.icon}</div>
            <h3 className="text-lg font-semibold mb-2 text-left">
              {service.title}
            </h3>
            <p className="text-gray-700 text-sm mb-4 text-left">
              {service.description}
            </p>
            <button className="self-end mt-auto border text-white p-4 rounded-full hover:bg-green-600 transition-colors flex items-center justify-center">
              <FaArrowRight color="#57cc99" />
            </button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default WasteServices;
